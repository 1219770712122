<template>
  <div id="app">
    <Nav />
    <div class="mt-50">
      <img class="top-img" :src="require(`@/assets/banner/banner-06${$i18n.locale == 'zh'?'z':'e'}.png`)" alt="" />
      <div class="record">
        <div class="record-bac"></div>
        <!-- 2021 -->
        <div class="record-li flex between" v-for="item in bigevent" :key="item.id">
          <div class="record-li-left">
            <img class="record-left-img1" :src="require(`@/assets/rec/${__commonJs['happenTimeFun'](item.eventtime * 1000,'y')}.png`)" alt="" />
            <div class="record-left-time color1 f-14">{{__commonJs['happenTimeFun'](item.eventtime * 1000,'ym')}}</div>
            <img class="record-left-img2" src="@/assets/rec/rec-2.png" alt="" />
          </div>
          <div class="record-li-right">
            <div class="record-right-hz">
              <img class="record-right-img1" :src="`${$baseUrl}${item.thumb}`" alt="">
              <img class="record-right-img2" src="@/assets/rec/rec-3.png" alt="">
              <img class="record-right-img3" src="@/assets/rec/rec-10.png" alt="">
            </div>
            <img class="record-right-img4" src="@/assets/rec/rec-9.png" alt="">
            <p class="color2 f-14">{{item.title}}</p>
            <p class="color1 f-12 mt-5">{{item.description}}</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<style scoped></style>
<script>
import Nav from "@/components/nav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      bigevent:[]
    };
  },
  computed: {},
  created() {
    this.chronicle()
  },
  methods: {
    chronicle(){
       this.$ajax.get("/cms/api/bigevent", {}).then((res) => {
        if (res.code == "200") {
          this.bigevent = res.data
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.record {
  // padding: 42px 2px 0 14px;
  position: relative;
  padding-bottom:20px;
  .record-li {
    padding: 42px 2px 0 14px;
    position: relative;
    z-index: 11;
    .record-li-left {
      .record-left-img1 {
        width: 122px;
        height: 35px;
      }
      .record-left-time {
        margin-top: 10px;
      }
      .record-left-time::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 8px solid #634636;
        margin-left: 10px;
        margin-bottom:1px;
      }
      .record-left-img2{
        width: 92px;
        height: 164px;
        margin-top:24px;
        position: relative;
        left:-16px;
      }
    }
    .record-li-right{
       width: 196px;
       margin-right:16px;
       text-align: right;
      .record-right-hz{
        position: relative;
        width: 100%;
        .record-right-img1{
          width: 100%;
          height: 131px;
          // background-image: url('../../../assets/rec/rec-5.png');
          // background-size: 100%;
          z-index: 2;
           position: relative;
           border-radius: 25px 0 25px 0;
        }
         .record-right-img2{
           width: 48px;
           height: 50px;
           position: absolute;
           top:-25px;
           right:-15px;
           z-index: 1;
        }
        .record-right-img3{
          width: 41px;
          height: 49px;
           position: absolute;
           bottom:0;
           left:0;
           z-index: 3;
        }
      }
     .record-right-img4{
       width: 127px;
       height: 19px;
       margin:6px 35px 10px 0;
     }
    }
  }
}
.record-bac {
  position: absolute;
  top: 0;
  left: 114px;
  background-image: url("../../../assets/rec/rec-4.png");
  background-size: 100%;
  width: 20px;
  height: 100%;
}
</style>

